module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kominictví Radomír Ďuriš","short_name":"Kominictví","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","icon":"src/images/gatsby_icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"856431b42658b4c01ca5839ea7ae04db"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
